import './App.css';
import HomeContainer from '../src/containers/HomeContainer/HomeContainer'
import { BrowserRouter, Route } from 'react-router-dom';
import AuthContainer from './containers/AuthContainer/AuthContainer';
import AdminContainer from './containers/AdminContainer/AdminContainer';
import { useState, useEffect } from 'react';


const App = () => {
  const [user,setUser] = useState(localStorage.getItem("AdminId"));

  useEffect(() => {
    setUser(localStorage.getItem("AdminId"))
  }, [])
  return (
    <BrowserRouter>
      <Route path="/" exact={true} component={HomeContainer} />
      <Route path="/admin" exact={true} component={AuthContainer} />
      <Route path="/admin/dashboard" exact={true} component={AdminContainer} />
    </BrowserRouter>
  );
}

export default App;
