import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyAptmC6q1Am5ZgDry5mJ3m2KIprFjp9idg",
    authDomain: "nena-aruna.firebaseapp.com",
    databaseURL: "https://nena-aruna.firebaseio.com",
    projectId: "nena-aruna",
    storageBucket: "nena-aruna.appspot.com",
    messagingSenderId: "937247928038",
    appId: "1:937247928038:web:b5211147b34e96608b1527",
    measurementId: "G-RY182C535Y"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firestore = firebaseApp.firestore();
const storage = firebaseApp.storage();

export {
  firestore,
  storage,
  firebaseApp as default
}


