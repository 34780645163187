import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { firestore } from '../../Firebase';
import './AuthContainer.css'
import logo from '../../assets/logo.png'
import { LoadingComponent } from '../../components/LoadingComponent/LoadingComponent';


const AuthContainer = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        firestore.collection("admin").doc("admin").get()
            .then(res => {
                const adminId = localStorage.getItem("AdminId")
                if (adminId === res.id) {
                    history.push("/admin/dashboard")
                }else{
                    setLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    }, [])

    const onChangeUserName = (e) => {
        setUsername(e.target.value);
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value);
    }

    const login = () => {
        firestore.collection("admin").doc("admin").get()
            .then(res => {
                if (username === res.data().username && password === res.data().password) {
                    localStorage.setItem("AdminId", res.id)
                    history.push("/admin/dashboard")
                } else {
                    alert("Invalid username or password")
                }
            }).finally(()=>{

            })
    }

    if(loading)
        return (<LoadingComponent />)

    return (
        <div className="container-fluid">
            <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="login-form">
                    <div className="row mb-5">
                        <div className="offset-3 col-6">
                            <img src={logo} width="150px" className="img-fluid logo"/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                        <input type="text" className="form-control" id="username" onChange={onChangeUserName} placeholder="User Name" />
                        </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col">
                        <input type="password" className="form-control" id="password" onChange={onChangePassword} placeholder="Password" />
                    </div>
                    </div>
                    
                    <div className="row mb-3">
                        <div className="col text-center">
                        <button type="submit" className="btn btn-primary" onClick={login}>Login</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AuthContainer
