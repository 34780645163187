import React, { useEffect, useState } from 'react'
import './AdminContainer.css';
import logo from '../../assets/logo.png'
import rotaLogo from '../../assets/logo_white.png'
import VirtualSeminar from '../../components/VirtualSeminar/VirtualSeminar'
import YoutubeLessons from '../../components/YoutubeLessons/YoutubeLessons'
import { useHistory } from 'react-router-dom';
import { firestore } from '../../Firebase';
import { LoadingComponent } from '../../components/LoadingComponent/LoadingComponent';

const AdminContainer = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        firestore.collection("admin").doc("admin").get()
            .then(res => {
                const adminId = localStorage.getItem("AdminId")
                if (adminId !== res.id) {
                    history.push("/admin")
                } else {
                    setLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    })

    const logout = () => {
        localStorage.removeItem("AdminId");
        history.push("/admin")
    }

    if (loading)
        return (<LoadingComponent />)

    return (
        <div>
            <nav className="navbar navbar-expand-sm navbar-light bg-light fixed-top justify-content-between" >
                <a className="navbar-brand" href="#">
                    <img src={logo} width="auto" height="50" className="d-inline-block align-top" alt="" loading="lazy" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="" href="/" >Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link btn btn-primary" href="" onClick={logout} >Logout</a>
                        </li>
                    </ul>
                </div>
            </nav>


            <div class="accordion" id="accordionExample">
                {/* <div class="card">
                    <div class="card-header" id="headingOne">
                        <h2 class="mb-0">
                            <button class="btn  btn-block text-left " type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Virtual Seminar
                            </button>
                        </h2>
                    </div>

                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div class="card-body">
                            <VirtualSeminar />
                        </div>
                    </div>
                </div> */}
                <div class="card">
                    <div class="card-header" id="headingOne">
                        <h2 class="mb-0">
                            <button class="btn  btn-block text-left " type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Mathematics
                            </button>
                        </h2>
                    </div>

                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div class="card-body">
                            <YoutubeLessons type="maths" />
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" id="headingTwo">
                        <h2 class="mb-0">
                            <button class="btn  btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Biology
                            </button>
                        </h2>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div class="card-body">
                            <YoutubeLessons type="bio" />
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" id="headingThree">
                        <h2 class="mb-0">
                            <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Commerce
                            </button>
                        </h2>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                        <div class="card-body">
                            <YoutubeLessons type="commerce" />
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" id="headingFour">
                        <h2 class="mb-0">
                            <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                ICT
                            </button>
                        </h2>
                    </div>
                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                        <div class="card-body">
                            <YoutubeLessons type="ict" />
                        </div>
                    </div>
                </div>
            </div>


            <div className="footer ">
                <div className="text">
                    <p>Crafted with ♥ by RotaractMora ✨</p>
                </div>
                <div>
                    <p>© 2020 Rotaract Club of University of Moratuwa</p>
                </div>
            </div>



        </div>
    );
}

export default AdminContainer;