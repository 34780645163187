import React, { useState, useEffect, useLayoutEffect } from 'react'
import './YoutubeLessons.css'
import { FaMinusCircle } from 'react-icons/fa';
import { firestore } from '../../Firebase';
import Loader from '../../components/LoaderComponent/LoaderComponent'

const List = (props) => {
    const deleteItem = async(id) => {
        await firestore.collection("videos").doc(id).delete();
    }

    const item = props.data
    return (
        item.map(data => {
            return (
                <li className="list-group-item d-flex justify-content-between align-items-center">
                    <sapn>{data.title}</sapn>
                    <div onClick={()=>deleteItem(data.id)} className="remove">
                        <FaMinusCircle />
                    </div>
                </li>
            )
        })
    )
}



const YoutubeLessons = (props) => {
    const [recorded, setRecorded] = useState([]);
    const [practical, setPractical] = useState([]);
    const [virtual, setVirtual] = useState([]);
    const [topic, setTopic] = useState("");
    const [link, setLink] = useState("");
    const [loading, setLoading] = useState(false);

    const getAllVideos = () => {

        const sessionsRef = firestore.collection("videos");
        sessionsRef.onSnapshot(querySnapshot => {
            let recorded = [];
            let virtual = [];
            let practical = [];
            querySnapshot.forEach(doc => {
                if (doc.data().type === "recorded") {
                    recorded.push({ ...doc.data(), id: doc.id });
                } else if (doc.data().type == "practical") {
                    practical.push({ ...doc.data(), id: doc.id });
                } else if (doc.data().type == "virtual") {
                    virtual.push({ ...doc.data(), id: doc.id });
                }
            });
            setVirtual(virtual);
            setRecorded(recorded);
            setPractical(practical);
        });

    }

    useEffect(() => {
        getAllVideos();
    }, [])

    const onChangeTopic = (e) => {
        setTopic(e.target.value);
    }

    const onChangeLink = (e) => {
        setLink(e.target.value);
    }

    const addLink = () => {
        setLoading(true);
        firestore.collection("videos").add({
            link: link,
            title: topic,
            type: props.type
        }).then(res=>{
            if (res.id) {
                alert("Video added successfully.")
            }
            setTopic("");
            setLink("");
        }).finally(()=>{
            setLoading(false)
        });
        
    }



    return (
        <div>
            <div className="form">
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <input value={topic} type="text" class="form-control" id="inputCity" placeholder="Topic" onChange={onChangeTopic} />
                    </div>
                    <div class="form-group col-md-6">
                        <input value={link} type="text" class="form-control" id="inputCity" placeholder="Link" onChange={onChangeLink} />
                    </div>
                    <div class="form-group col-md-2">
                        <button type="submit" class="btn btn-primary" onClick={addLink} disabled={loading}>
                        <Loader loading={loading} content=" Adding" component={<span>Add</span>} />
                        </button>
                    </div>
                </div>


            </div>


            <ul className="list-group list-group-flush">

                {props.type === "recorded" ? <List data={recorded} /> : ""}
                {props.type === "practical" ? <List data={practical} /> : ""}
                {props.type === "virtual" ? <List data={virtual} /> : ""}
            </ul>
        </div>
    )
}

export default YoutubeLessons
