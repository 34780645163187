import React from 'react'
import './LoaderConponent.css'

export const LoaderComponent = (props) => {

    if (props.loading) {
        return (
            <div>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Loading...</span>{props.content}
      
            </div>
             )
    } else
        return (props.component)
}

export default LoaderComponent;
