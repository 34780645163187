import VideoCard from '../VideoCard/VideoCard'
import React, { Component, useState, useEffect } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import './CardSlider.css';
import { firestore } from '../../Firebase';

const list = [
  { name: 'item1' },
  { name: 'item2' },
  { name: 'item3' },
  { name: 'item4' },
  { name: 'item5' }
];
 
export const Menu = (list) =>
  list.map(data => {
    return <VideoCard key={data.title} data={data} />
  });
 
 
const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};
 
 
const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev arrow' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next arrow' });


const CardSlider = (props) =>{
   const menu = Menu(props.data)

  return (
    <div className="App">
      <ScrollMenu
        data={menu}
        arrowLeft={ArrowLeft}
        arrowRight={ArrowRight}
        selected={false}
      />
    </div>
  );
 }


 export default CardSlider;