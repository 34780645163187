import React, { useEffect, useState } from 'react'
import './HomeContainer.css';
import logo from '../../assets/logo.png'
import rotaLogo from '../../assets/logo_white.png'
import online from '../../assets/online.png'
import c_soon from '../../assets/c_soon.png'
import learYouLogo from '../../assets/learnYouLogo.png'
import timetable from '../../assets/timetable.svg'
import CardSlider from '../../components/CardSlider/CardSlider'
import { firestore, storage } from '../../Firebase';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from '../../components/LoaderComponent/LoaderComponent';
import { Navbar } from 'react-bootstrap';
import { NavDropdown } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import {
    faYoutube,
    faFacebook,
    faTwitter,
    faInstagram,
    faMediumM,
    faLinkedin
} from "@fortawesome/free-brands-svg-icons";


const HomeContainer = () => {
    const [session, setSession] = useState("");
    const [recorded, setRecorded] = useState([]);
    const [practical, setPractical] = useState([]);
    const [virtual, setVirtual] = useState([]);
    const [maths, setmaths] = useState([]);
    const [bio, setbio] = useState([]);
    const [commerce, setcommerce] = useState([]);
    const [ict, setict] = useState([]);
    const [loading, setLoading] = useState(false);



    const getAllVideos = async () => {
        let recorded = [];
        let virtual = [];
        let practical = [];
        let maths = [];
        let bio = [];
        let commerce = [];
        let ict = [];
        const sessionsRef = firestore.collection("videos");
        const snapshot = await sessionsRef.get();
        snapshot.forEach(doc => {
            if (doc.data().type === "recorded") {
                recorded.push(doc.data());
            } else if (doc.data().type == "practical") {
                practical.push(doc.data());
            } else if (doc.data().type == "virtual") {
                virtual.push(doc.data());
            } else if (doc.data().type == "maths") {
                maths.push(doc.data());
            } else if (doc.data().type == "bio") {
                bio.push(doc.data());
            } else if (doc.data().type == "commerce") {
                commerce.push(doc.data());
            } else if (doc.data().type == "ict") {
                ict.push(doc.data());
            }
        });
        setVirtual(virtual);
        setRecorded(recorded);
        setPractical(practical);
        setmaths(maths);
        setbio(bio);
        setcommerce(commerce);
        setict(ict);

    }

    const download = () => {
        setLoading(true);
        const fileRef = storage.ref('files').child(session.file);
        fileRef.getDownloadURL()
            .then(url => {
                const link = document.createElement('a');
                link.href = url;
                link.download = "Virtual Seminar";

                document.body.appendChild(link);
                link.click();
                link.remove();

            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        firestore.collection("sessions").doc("tdawVUJBykYoG7cjx0Jg").get()
            .then(res => {
                setSession(res.data());
            })
            .catch(err => {
                console.log(err);
            })

        getAllVideos();


    }, [])



    return (
        <div className="overflow-hidden">
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top justify-content-between" >
                <a className="navbar-brand" href="#">
                    <img src={logo} width="auto" height="50" className="d-inline-block align-top" alt="" loading="lazy" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="#home">Home <span className="sr-only">(current)</span></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#about">About</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href="#math">Mathematics</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#bio">Biology</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#commerce">Commerce</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#ICT">ICT</a>
                        </li>
                        {/* <li className="nav-item">
                            <Navbar.Collapse id="navbar-dark-example">
                                <Nav>
                                    <NavDropdown
                                        id="nav-dropdown-dark-example"
                                        title="A/L Online Seminar"
                                        menuVariant="dark"
                                    >
                                        <NavDropdown.Item href="#math">Mathematics</NavDropdown.Item>
                                        <NavDropdown.Item href="#bio">Biology</NavDropdown.Item>
                                        <NavDropdown.Item href="#Com">Commerce</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                            </Navbar.Collapse>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href="#virtual-class">Online Seminar</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#recorded-lessons">Recorded Lessons</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#science-practicals">Science Practicals</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#previous-seminars">Previous Seminars</a>
                        </li> */}
                        {/* <li className="nav-item">
                            <a className="nav-link btn btn-primary" href="#" >LearnYou</a>
                        </li> */}
                        <li className="nav-item youtube">
                            <a className="nav-link btn " href="https://www.youtube.com/channel/UCmv-qBU7MMalmjtDPb_PQfA" target="_blank">YouTube</a>
                        </li>
                    </ul>
                </div>
            </nav>

            <div className=" cover d-flex align-items-center justify-content-center" id="home">
                <div className="align-items-center justify-content-center logo">
                    <img src={rotaLogo} width="200" />
                    <div><br />
                        <span className="body">"Once you educate yourself<br /> you're left with choices"</span><br />
                        <span>-Yvon Choinard-</span> <br /><br /><br />
                        <span className="title">නැණ අරුණ <br /> Nena Aruna</span>
                    </div>
                </div>
            </div>

            <div className="row d-flex about " id="about" >
                <div className="col-md-6 about-img justify-content-center align-items-center d-flex ">
                    <div>
                        <span className="quote">Education never ends Watson. <br />It is a series of lessons, with the greatest for the last.</span>
                        <br /> <span>-Arthur Conon Doyle-</span>
                    </div>

                </div>
                <div className="col-md-6 justify-content-center align-items-center d-flex body">

                    <p>
                        "Nena Aruna" is an initiative by Rotaract Club of University of Moratuwa to raise the standards of education of those facing GCE Advanced level, who have limited access to educational materials. This is mainly launched as a series of seminars focusing on the main subject streams of Advanced Level Mathematics, Biology and Commerce. Due to the Covid-19 pandemic and the fact that more students will be able to participate via online platforms, we have taken steps to provide this service via Zoom.
                    </p>
                </div>
            </div>

            <div className="row d-flex about " id="about_1" >
                <div className="col-md-6 justify-content-center align-items-center d-flex body">

                    <p>
                        මෙවර උසස් පෙළ නංගිලා මල්ලිලා වෙනුවෙන් ක්‍රියාත්මක කෙරෙන සම්මන්ත්‍රණ මාලාව පහත විශයන් මූලික කරගනිමින්, පසුගිය ප්‍රශ්න පත්‍ර හා අනුමාන ප්‍රශ්න පත්‍ර සාකච්ඡා කර ඔබේ විශයානුබද්ධ ගැටලු වලට විසඳුම් Zoom තාක්ෂණය ඔස්සේ ලබා දීම අපගේ බලාපොරොත්තුවයි.
                        <br />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen-fill" viewBox="0 0 16 16">
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z" />
                        </svg> භෞතික විද්‍යාව<br />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen-fill" viewBox="0 0 16 16">
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z" />
                        </svg> රසායනික විද්‍යාව<br />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen-fill" viewBox="0 0 16 16">
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z" />
                        </svg> සංයුක්ත ගණිතය<br />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen-fill" viewBox="0 0 16 16">
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z" />
                        </svg> ජීව විද්‍යාව<br />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen-fill" viewBox="0 0 16 16">
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z" />
                        </svg> ආර්ථික විද්‍යාව<br />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen-fill" viewBox="0 0 16 16">
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z" />
                        </svg> ගිණුම්කරණය<br />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen-fill" viewBox="0 0 16 16">
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z" />
                        </svg> තොරතුරු තාක්ෂණය<br />
                        <br />
                        <b>මෙම වෙබ් අඩවියේ සඳහන් LINKS හරහා අපගේ WhatsApp Groups වෙත සම්බන්ධ වන්න.</b>
                    </p>
                </div>
                <div className="col-md-6 about_1-img justify-content-center align-items-center d-flex ">
                    <div>
                        <span className="quote">Select stream.</span>
                        <br /> <span><a href="#math"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Mathematics</button></a></span>
                        <br /> <span><a href="#bio"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Biology</button></a></span>
                        <br /> <span><a href="#commerce"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Commerce</button></a></span>
                        <br /> <span><a href="#ICT"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">ICT Subject</button></a></span>
                    </div>

                </div>
            </div>

            <div className="container" id="virtual-class">
                <div className="title-virtual" >
                    <h1>Nena Aruna</h1>
                    <h3>Stay Tuned</h3>
                </div>
                <div className="row d-flex">
                    {/* <div className="col-md-6">
                        <div className="row" style={{ flex: 1 }}>
                            <div className="topic">
                                <h5>Subject :</h5>
                            </div>
                            <div className="details">
                                <p>{session.subject}</p>
                            </div>
                        </div>
                        <div className="row" style={{ flex: 1 }}>
                            <div className="topic">
                                <h5>Lesson :</h5>
                            </div>
                            <div className="details">
                                <p>{session.lesson}</p>
                            </div>
                        </div>
                        <div className="row" style={{ flex: 1 }}>
                            <div className="topic">
                                <h5>Medium :</h5>
                            </div>
                            <div className="details">
                                <p>{session.medium}</p>
                            </div>
                        </div>
                        <div className="row" style={{ flex: 1 }}>
                            <div className="topic">
                                <h5>Date :</h5>
                            </div>
                            <div className="details">
                                <p>{session.date}</p>
                            </div>
                        </div>
                        <div className="row" style={{ flex: 1 }}>
                            <div className="topic">
                                <h5>Time :</h5>
                            </div>
                            <div className="details">
                                <p>{session.time}</p>
                            </div>
                        </div>
                        <div className="row" style={{ flex: 1 }}>
                            <div className="topic">
                                <h5>Seminar No :</h5>
                            </div>
                            <div className="details">
                                <p>{session.seminarNo}</p>
                            </div>
                        </div>
                        {session.material ? (<div className="row" style={{ flex: 1 }}>
                            <div className="topic">
                                <h5>Seminar Materials :</h5>
                            </div>
                            <div className="details">

                                <button type="button" class="btn btn-secondary btn-sm" onClick={download} disabled={loading}>
                                    <Loader loading={loading} content={" Downloding"} component={<span>Download</span>} />


                                </button>
                            </div>
                        </div>) : (<div></div>)}

                    </div>

                    <div className="col-md-6 join">
                        <div>
                            <h3>Join virtual seminar</h3>
                        </div>
                        <div>
                            <img src={online} className="img-online img-fluid" />
                        </div>

                        <a type="button" className="btn btn-primary btn-md" href={session.zoom} target="_blank">Join via Zoom</a>
                        <span style={{ padding: 10 }}>or</span>
                        <a type="button" className="btn btn-primary btn-md" href={session.youtube} target="_blank">Join via YouTube</a>


                    </div> */}
                    {/*<table class="table table-hover" style={{ fontSize: "20px", width: "100%", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>
                        <tbody>
                            <tr>
                                <th scope="row">Monday</th>
                                <td>Physics<br /><span style={{ fontSize: "17px" }}>6.00 pm - 9.00 pm</span><br /><a href="https://zoom.us/j/93706600280?pwd=RDlmcUhlTGtkZldKL3hlVkZIa1Vadz09" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Join</button></a></td>
                                <td>Economics<br /><span style={{ fontSize: "17px" }}>6.00 pm - 9.00 pm</span><br /><a href="https://zoom.us/j/93843217701?pwd=QmhHdXFMUHhZWjFYaHNEY0VwL2o0QT09" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Join</button></a></td>
                            </tr>
                            <tr>
                                <th scope="row">Tuesday</th>
                                <td>Chemistry<br /><span style={{ fontSize: "17px" }}>6.00 pm - 9.00 pm</span><br /><a href=" https://zoom.us/j/98801287342?pwd=TkJYbTliSGRSWFd0aysrQlJ2VCt0dz09" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Join</button></a></td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">Wednesday</th>
                                <td>Biology<br /><span style={{ fontSize: "17px" }}>6.00 pm - 9.00 pm</span><br /><a href="https://zoom.us/j/98742071171?pwd=NWQ3V2hlZ0krN1Z4cm1xK2FDSjNadz09" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Join</button></a></td>
                                <td>Economics<br /><span style={{ fontSize: "17px" }}>6.00 pm - 9.00 pm</span><br /><a href="https://zoom.us/j/93843217701?pwd=QmhHdXFMUHhZWjFYaHNEY0VwL2o0QT09" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Join</button></a></td>
                            </tr>
                            <tr>
                                <th scope="row">Thursday</th>
                                <td>Maths<br /><span style={{ fontSize: "17px" }}>6.00 pm - 9.00 pm</span><br /><a href="https://zoom.us/j/99136586228?pwd=SVRIdHVlTEJVdFhsQnRqTm9DL0h1Zz09" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Join</button></a></td>
                                <td>Accounting<br /><span style={{ fontSize: "17px" }}>6.00 pm -  11.59pm</span><br /><a href="https://zoom.us/j/97009853725?pwd=YnRGSkRXS25CYzQwTWdNUjJPU0hmUT09" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Join</button></a></td>
                            </tr>
                            <tr>
                                <th scope="row">Friday</th>
                                <td>ICT<br /><span style={{ fontSize: "17px" }}>6.00 pm - 9.00 pm</span><br /><a href="https://zoom.us/j/94477204209?pwd=czY2TFc1Z3FEZEIxblM3Nm5MTjI3dz09" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Join</button></a></td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">Saturday</th>
                                <td>Chemistry<br /><span style={{ fontSize: "17px" }}>9.00 am - 12.00 pm</span><br /><a href=" https://zoom.us/j/98801287342?pwd=TkJYbTliSGRSWFd0aysrQlJ2VCt0dz09" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Join</button></a></td>
                                <td>Physics<br /><span style={{ fontSize: "17px" }}>6.00 pm - 9.00 pm</span><br /><a href="https://zoom.us/j/93706600280?pwd=RDlmcUhlTGtkZldKL3hlVkZIa1Vadz09" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Join</button></a></td>
                            </tr>
                            <tr>
                                <th scope="row">Sunday</th>
                                <td>ICT<br /><span style={{ fontSize: "17px" }}>9.00 am - 12.00 pm</span><br /><a href="https://zoom.us/j/94477204209?pwd=czY2TFc1Z3FEZEIxblM3Nm5MTjI3dz09" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Join</button></a></td>
                                <td>Maths | Biology<br /><span style={{ fontSize: "17px" }}>6.00 pm - 9.00 pm</span><br /><a href=" https://zoom.us/j/99136586228?pwd=SVRIdHVlTEJVdFhsQnRqTm9DL0h1Zz09" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Join (Maths)</button></a>
                                    <br /><a href="https://zoom.us/j/98742071171?pwd=NWQ3V2hlZ0krN1Z4cm1xK2FDSjNadz09" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Join (Bio)</button></a></td>
                            </tr>
                        </tbody>
                </table>*/}
                </div>

            </div>


            <div className="row learn-you justify-content-center align-items-center d-flex " >
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 align-items-center justify-content-center d-flex">
                            <img src={learYouLogo} width="300px" />
                        </div>
                        <div className="col-md-8" style={{ textAlign: "justify" }}>
                            <p>
                                The project LearnYou is launched as a sub project of Nena Aruna 2020/21 by Rotaract Mora. LearnYou is an online platform where school students and university students can request answers for their subject related problems. Users can discuss problems and answers using this platform. Using this platform students will be able to share their knowledge with others.
                            </p>
                            <div className="row justify-content-center">
                                <br /> <span><a href="https://learnyou.rotaractmora.org/" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-primary">Go to LearnYou</button></a></span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="card text-center slider" id="math">
                {/*<div className="card-header">
                    <h5>Mathematics</h5>
            </div>*/}
                <container>
                    {/* <Row>
                        <Col sm={4} className="topic" style={{ paddingLeft: "5%" }}><br />
                            <div class="container">
                                <div class="col-md-12 text-center">

                                    <span><b>Join Whatsapp Group</b><br />(try one by one and join only one group)</span>
                                    <br /> <span><a href="https://chat.whatsapp.com/GZQqQnwQ227GHkco1qpCuw" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-success">Group 01</button></a></span>
                                    <br /> <span><a href="https://chat.whatsapp.com/HcSwolvr2TGCwAbpNuccSh" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-success">Group 02</button></a></span>
                                    <br /> <span><a href="https://chat.whatsapp.com/FiKI3miBm1e779IjuMjrdy" target="_blank"><button style={{ width: "100%", marginTop: "10px" }} type="button" class="btn btn-success">Group 03</button></a></span>
                                    <br /><br /> <span>🔰 ICT students make sure to join one of the ICT groups too</span>
                                </div>
                            </div>

                        </Col>
                        <Col sm={8} style={{ marginRight: "0px" }}>
                            <div className="card-body">
                                <CardSlider data={maths} />
                            </div>
                        </Col>
                    </Row> */}


                    {/*<Col>
                        <Row className="topic" style={{ paddingLeft: "5%" }}><br />
                            <div class="container">
                                <div class="col-md-12 text-center">

                                    <span><b>Join Whatsapp Group</b><br />(try one by one and join only one group)</span>
                                    <br /> <span><a href="https://chat.whatsapp.com/GZQqQnwQ227GHkco1qpCuw" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 01</button></a></span>
                                    <br /> <span><a href="https://chat.whatsapp.com/HcSwolvr2TGCwAbpNuccSh" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 02</button></a></span>
                                    <br /> <span><a href="https://chat.whatsapp.com/FiKI3miBm1e779IjuMjrdy" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 03</button></a></span>
                                    <br /><br /> <span>🔰 ICT students make sure to join one of the ICT groups too</span>
                                </div>
                            </div>

                        </Row>
                        <Row>
                            <div className="card-body" style={{width:"80%"}}>
                                <CardSlider data={maths} />
                            </div>
                        </Row>
                </Col>*/}

                    {/* <br />
                    <span><b>Join Whatsapp Group</b><br />(try one by one and join only one group)</span>
                    <br /> <span><a href="https://chat.whatsapp.com/GZQqQnwQ227GHkco1qpCuw" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 01</button></a></span>
                    <br /> <span><a href="https://chat.whatsapp.com/HcSwolvr2TGCwAbpNuccSh" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 02</button></a></span>
                    <br /> <span><a href="https://chat.whatsapp.com/FiKI3miBm1e779IjuMjrdy" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 03</button></a></span>
                    <br /><br /> <span>🔰 ICT students make sure to join one of the ICT groups too</span> */}
                </container>
            </div>

            <div className="card text-center slider" id="bio">
                <div className="card-header">
                    <h5>Physical and Biology</h5>
                </div>
                <container>
                    <Col>
                        <Row className="topic" style={{ paddingLeft: "5%" }}><br />
                            <div class="container">{/*
                                <div class="col-md-12 text-center">

                                    <span><b>Join Whatsapp Group</b><br />(try one by one and join only one group)</span>
                                    <br /> <span><a href="https://chat.whatsapp.com/BSAlIHnmEgI9vgo9AJUSEW" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 01</button></a></span>
                                    <br /> <span><a href="https://chat.whatsapp.com/HrhSivhz6JcAIKAFBGdpa7" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 02</button></a></span>
                                    <br /> <span><a href="https://chat.whatsapp.com/LImy5ri7S7i1WBdYOGVeOT" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 03</button></a></span>

                                </div>*/}
                </div> 

                        </Row>
                        <Row>
                            <div className="card-body"  style={{width:"80%"}}>
                                <CardSlider data={bio} />
                            </div>
                        </Row>
                    </Col>
                    {/* <br />
                    <span><b>Join Whatsapp Group</b><br />(try one by one and join only one group)</span>
                    <br /> <span><a href="https://chat.whatsapp.com/BSAlIHnmEgI9vgo9AJUSEW" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 01</button></a></span>
                    <br /> <span><a href="https://chat.whatsapp.com/HrhSivhz6JcAIKAFBGdpa7" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 02</button></a></span>
                    <br /> <span><a href="https://chat.whatsapp.com/LImy5ri7S7i1WBdYOGVeOT" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 03</button></a></span> */}

                </container>
            </div>

            <div className="card text-center slider" id="commerce">
                <div className="card-header">
                    <h5>Commerce</h5>
                </div>
                <container>
                    <Col>
                        <Row className="topic" style={{ paddingLeft: "5%" }}><br />
                            <div class="container">
                                {/*<div class="col-md-12 text-center">

                                    <span><b>Join Whatsapp Group</b><br />(try one by one and join only one group)</span>
                                    <br /> <span><a href="https://chat.whatsapp.com/Gj0jPQ4duGM0Rsfzj0HZpd" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 01</button></a></span>
                                    <br /> <span><a href="https://chat.whatsapp.com/J9lgzMNomAG4AwIeZMzckO" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 02</button></a></span>
                                    <br /> <span><a href="https://chat.whatsapp.com/BOL2bgnih9yEfulC5qILH1" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 03</button></a></span>
                                    <br /><br /> <span>🔰 ICT students make sure to join one of the ICT groups too</span>
                </div>*/}
                </div>

                        </Row>
                        <Row>
                            <div className="card-body"  style={{width:"80%"}}>
                                <CardSlider data={commerce} />
                            </div>
                        </Row>
                    </Col>
                    {/* <br />
                    <span><b>Join Whatsapp Group</b><br />(try one by one and join only one group)</span>
                    <br /> <span><a href="https://chat.whatsapp.com/Gj0jPQ4duGM0Rsfzj0HZpd" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 01</button></a></span>
                    <br /> <span><a href="https://chat.whatsapp.com/J9lgzMNomAG4AwIeZMzckO" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 02</button></a></span>
                    <br /> <span><a href="https://chat.whatsapp.com/BOL2bgnih9yEfulC5qILH1" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 03</button></a></span>
                    <br /><br /> <span>🔰 ICT students make sure to join one of the ICT groups too</span> */}
                </container>
            </div>

            <div className="card text-center slider" id="ICT">
                <div className="card-header">
                    <h5>ICT Subject</h5>
                </div>
                <container>
                    <Col>
                        <Row className="topic" style={{ paddingLeft: "5%" }}><br />
                            <div class="container">
                                {/*<div class="col-md-12 text-center">

                                    <span><b>Join Whatsapp Group</b><br />(try one by one and join only one group)</span>
                    <br /> <span><a href="https://chat.whatsapp.com/HuvZMh87a9Q5frl9lrHTOL" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 01</button></a></span>
                    <br /> <span><a href="https://chat.whatsapp.com/Hk4pJCEgcE70rD5cRSsVa6" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 02</button></a></span>
                    <br /> <span><a href="https://chat.whatsapp.com/H1Ye1aVGK1j4s7g7Z2DBfd" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 03</button></a></span>
                
                </div>*/}
                            </div>

                        </Row>
                        <Row>
                            <div className="card-body" style={{width:"80%"}}>
                                <CardSlider data={ict} />
                            </div>
                        </Row>
                    </Col>
                    {/* <br />
                    <span><b>Join Whatsapp Group</b><br />(try one by one and join only one group)</span>
                    <br /> <span><a href="https://chat.whatsapp.com/HuvZMh87a9Q5frl9lrHTOL" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 01</button></a></span>
                    <br /> <span><a href="https://chat.whatsapp.com/Hk4pJCEgcE70rD5cRSsVa6" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 02</button></a></span>
                    <br /> <span><a href="https://chat.whatsapp.com/H1Ye1aVGK1j4s7g7Z2DBfd" target="_blank"><button style={{ width: "30%", marginTop: "10px" }} type="button" class="btn btn-success">Group 03</button></a></span> */}
                </container>
            </div>

            {/* <div className="card text-center slider" id="recorded-lessons">
                <div className="card-header">
                    <h5>Zoom Sessions</h5>
                </div>
                <div className="card-body">
                    <CardSlider data={recorded} />

                    <img src={c_soon} className="rounded mx-auto d-block" style={{ width: "20%" }} />
                </div>
            </div> */}

            {/* <div className="card text-center slider" id="recorded-lessons">
                <div className="card-header">
                    <h5>Recorded Lessons</h5>
                </div>
                <div className="card-body">
                    <CardSlider data={recorded} />

                    <img src={c_soon} className="rounded mx-auto d-block" style={{ width: "20%" }} />
                </div>
            </div>

            <div className="card text-center slider" id="science-practicals">
                <div className="card-header">
                    <h5>Science Practicals</h5>
                </div>
                <div className="card-body">
                    <CardSlider data={practical} />
                </div>
            </div>

            <div className="card text-center slider" id="previous-seminars">
                <div className="card-header">
                    <h5>Previous Seminars</h5>
                </div>
                <div className="card-body">
                    <CardSlider data={virtual} />
                </div>
            </div> */}

            <div className="footer ">
                <div className="text">
                    <p>Crafted with ♥ by RotaractMora ✨</p>
                </div>
                <div>
                    <p>© 2021 Rotaract Club of University of Moratuwa. All Rights Reserved.</p>
                </div>
                <div className="social-container">
                    <a href="https://www.youtube.com/user/rotaractmora"
                        className="social">
                        <FontAwesomeIcon icon={faYoutube} size="1x" />
                    </a>
                    <a href="https://www.facebook.com/rotaractmora"
                        className="social">
                        <FontAwesomeIcon icon={faFacebook} size="1x" />
                    </a>
                    <a href="https://twitter.com/RotaractMora" className="social">
                        <FontAwesomeIcon icon={faTwitter} size="1x" />
                    </a>
                    <a href="https://instagram.com/rotaractmora"
                        className="social">
                        <FontAwesomeIcon icon={faInstagram} size="1x" />
                    </a>
                    <a href="https://www.linkedin.com/company/rotaract-club-of-university-of-moratuwa"
                        className="social">
                        <FontAwesomeIcon icon={faLinkedin} size="1x" />
                    </a>
                    <a href="https://medium.com/rotaractmora/"
                        className="social">
                        <FontAwesomeIcon icon={faMediumM} size="1x" />
                    </a>
                </div>
            </div>



        </div>
    );
}

export default HomeContainer;