import React from 'react'
import './VideoCard.css'

const VideoCard = (props) => {
    return (
        <div className="row video">
            <div className="card">
                <iframe
                    width="250"
                    height="160"
                     src={props.data.link}
                    frameborder="0"
                    allow="accelerometer; 
            autoplay; clipboard-write; encrypted-media; gyroscope;
             picture-in-picture"
                    allowfullscreen='true'></iframe>

                <div className="card-body">
                    <p className="card-text">{props.data.title}</p>
                </div>
            </div>
        </div>
    )
}

export default VideoCard


   
